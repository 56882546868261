import React, { useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist'
import { PDFDocument } from 'pdf-lib';
import './assets/chota.css'
// @ts-ignore
import worker from "pdfjs-dist/build/pdf.worker.entry";
import { Buffer } from 'buffer'
// @ts-ignore
import * as QRCode from 'qrcode'
import InvoiceDetailsForm from './InvoiceDetailsForm.component';
import { form } from './models';

interface invoice {
  sellerName: string,
  vatRegistrationNumber: string,
  invoiceVatTotal: string,
  invoiceTimestamp: string,
  invoiceTotal: string
}

function App() {
  const [qr, setqr] = useState('')
  const [formDataCalif, setFormDataCalif] = useState<form>({
    companyName: 'AL SHAMS STORES TRADING COMPANY',
    VATNumber: '311468922600003',
    leftPercent: '40',
    topPercent: '10'
  })
  const [formDataShams, setFormDataShams] = useState<form>({
    companyName: 'SHAMS TRADING COMPANY',
    VATNumber: '311278176700003',
    leftPercent: '55',
    topPercent: '90'
  })
  const handleFile = async (event: React.ChangeEvent, company: 'calif' | 'shams') => {
    const { pdfDocObj, pdfProxyObj, filename } = await getPDFdocObjAndProxyObj(event)
    const countPromises = await invoiceDetailsToTextArray(pdfProxyObj)
    const qrCodeSizePx = 100
    // Wait for all pages and join text
    Promise.all(countPromises).then(async (texts) => {
      console.log(texts)
      const pages = pdfDocObj.getPages();
      const firstPage = pages[0];
      const { width, height } = firstPage.getSize();
      const invoiceData = {
        sellerName: '',
        vatRegistrationNumber: '',
        invoiceTimestamp: '',
        invoiceTotal: '',
        invoiceVatTotal: '',
      }
      let formData: form
      if (company === 'calif') {
        const invoiceDateAndTime = new Date(texts[0][texts[0].findIndex(text => text.toLowerCase().includes('date')) + 2]);
        invoiceDateAndTime.setHours(9, 0, 0, 0)
        invoiceData.sellerName = formDataCalif.companyName
        invoiceData.vatRegistrationNumber = formDataCalif.VATNumber
        invoiceData.invoiceTimestamp = invoiceDateAndTime.toISOString()
        invoiceData.invoiceTotal = texts[0][texts[0].length - 5].replace('SR', '').replace(',', '')
        invoiceData.invoiceVatTotal = texts[0][texts[0].length - 2].replace('SR', '').replace(',', '')
        formData = formDataCalif
      } else {
        const invoiceDateAndTime = new Date(texts[0][texts[0].findIndex(text => text.toLowerCase().includes('tax invoice')) + 2]);
        invoiceDateAndTime.setHours(9, 0, 0, 0)
        invoiceData.sellerName = formDataShams.companyName
        invoiceData.vatRegistrationNumber = formDataShams.VATNumber
        invoiceData.invoiceTimestamp = invoiceDateAndTime.toISOString()
        invoiceData.invoiceTotal = texts[0][texts[0].length - 4].replace('SAR', '').replace(',', '')
        invoiceData.invoiceVatTotal = texts[0][texts[0].length - 1].replace('SAR', '').replace(',', '')
        formData = formDataShams
      }

      const invoice = createInvoice(invoiceData);
      QRCode.toDataURL(invoice, { version: 10 }, function (err: string, url: string) {
        console.error(err)
        setqr(url)
        fetch(url)
          .then(res => res.arrayBuffer())
          .then(buffer => pdfDocObj.embedPng(buffer))
          .then(img => {
            firstPage.drawImage(img, {
              x: width * Number(formData.leftPercent) / 100 - qrCodeSizePx / 2,
              y: height * (1 - Number(formData.topPercent) / 100) - qrCodeSizePx / 2,
              width: qrCodeSizePx,
              height: qrCodeSizePx,
            })
            pdfDocObj.save().then(bytes => {
              const blob = new Blob([bytes], { type: 'application/pdf' })
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              const fileName = filename + '-qr.pdf';
              link.download = fileName;
              link.click();
            })
          })
      })
    })
  }
  async function invoiceDetailsToTextArray(pdf: pdfjsLib.PDFDocumentProxy) {
    const maxPages = pdf._pdfInfo.numPages
    const countPromises: Promise<string[]>[] = []
    for (let i = 1; i <= maxPages; i++) {
      const page = pdf.getPage(i)
      countPromises.push(page.then(page => {
        // add page promise
        const textContent = page.getTextContent();
        return textContent.then((text) => {
          // return content promise
          // @ts-ignore
          return text.items.map(function (s) { return s.str as string; }); // value page text 
        });
      }));
    }
    return countPromises
  }
  async function getPDFdocObjAndProxyObj(event: React.ChangeEvent) {
    const target = event.target as HTMLInputElement
    const blob = target?.files?.[0] as File
    const filename = blob.name
    const base64 = (await blobToBase64(blob)) as string
    const pdfDoc = await PDFDocument.load(await blob.arrayBuffer())
    pdfjsLib.GlobalWorkerOptions.workerSrc = worker;
    const pdf = await pdfjsLib.getDocument(base64).promise
    return { pdfProxyObj: pdf, pdfDocObj: pdfDoc, filename: filename }
  }
  const blobToBase64 = (blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }
  const createInvoice = ({
    sellerName,
    vatRegistrationNumber,
    invoiceVatTotal,
    invoiceTimestamp,
    invoiceTotal
  }: invoice) => {
    return Buffer.concat([
      getTLVBuf(1, sellerName),
      getTLVBuf(2, vatRegistrationNumber),
      getTLVBuf(3, invoiceTimestamp),
      getTLVBuf(4, invoiceTotal),
      getTLVBuf(5, invoiceVatTotal),
    ]).toString('base64')
  }
  const getTLVBuf = (tagNum: number, tagValue: string) => {
    const tagBuf = Buffer.from(toHex(tagNum), 'utf-8')
    const tagValueLenBuf = Buffer.from(toHex(tagValue.length), 'utf-8')
    const tagValueBuf = Buffer.from(tagValue, 'utf-8')
    const bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
    return Buffer.concat(bufsArray)
  }
  const toHex = (value: number | string): string => {
    let hex = value.toString(16);

    if ((hex.length % 2) > 0) {
      hex = '0' + hex;
    }
    return Buffer
      .from(hex, 'hex')
      .toString('utf-8');
  }
  // const toTlv = (tag: number, value: string): string => {
  //   return toHex(tag) + toHex(getValueByteLength(value)) + value;
  // }
  // const getValueByteLength = (value: string): number => {
  //   return Buffer.byteLength(value, 'hex');
  // }

  return (
    <div className="container is-full-screen is-vertical-align is-horizontal-align">
      <div className="card">
        <InvoiceDetailsForm formData={formDataCalif} onChange={setFormDataCalif}></InvoiceDetailsForm>
        <input type={'file'} onChange={(event) => handleFile(event, 'calif')} />
        <img src={qr} />
      </div>
      <div className="card">
        <InvoiceDetailsForm formData={formDataShams} onChange={setFormDataShams}></InvoiceDetailsForm>
        <input type={'file'} onChange={(event) => handleFile(event, 'shams')} />
        <img src={qr} />
      </div>
    </div>
  );
}

export default App;
