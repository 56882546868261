import React, { ChangeEvent, useState } from 'react';
import { form } from './models';

type formNames = keyof form
function InvoiceDetailsForm(props: { formData: form, onChange: React.Dispatch<React.SetStateAction<form>> }) {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target
        const name: formNames = target.id as formNames
        props.onChange({ ...props.formData, [name]: target.value })
    }
    return (
        <form>
            <input type={"text"} id={"companyName"} value={props.formData.companyName} onInput={handleChange}></input>
            <input type={"text"} id={"VATNumber"} value={props.formData.VATNumber} onChange={handleChange}></input>
            <input type={"range"} min={"0"} max={"100"} id={"leftPercent"} value={props.formData.leftPercent} onChange={handleChange}></input> {props.formData.leftPercent}%
            <input type={"range"} min={"0"} max={"100"} id={"topPercent"} value={props.formData.topPercent} onChange={handleChange}></input>{props.formData.topPercent}%
        </form>
    )
}
export default InvoiceDetailsForm